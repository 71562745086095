import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

// Atoms
import TextField from "../../../Atoms/TextField"

var h =0

function MSolicitarSalesPart(props){
    const {
        onClose,
    } = props

    const [errors, setErrors] = useState({})
    const [name, setName] = useState('')
    const [rut, setRut] = useState('')
    const [email, setEmail] = useState('')
    const [phoneContact, setPhoneContact] = useState('')
    const [isClient, setIsClient] = useState('-1')
    const [URLFrame, setURLFrame] = useState('')
    const [showForm, setShowForm] = useState(true)
    const [disabledContinua, setDisabledContinua] = useState(false)
    const [familia] = useState(localStorage.getItem('id_familia'))
    const [chat_mx, setChat_mx] = useState('')


    const onSubmitForm = data => {
        data.preventDefault()
        setDisabledContinua(true);
     console.info(formulario)
        let errors = {}
        console.log(isClient)
        if (isClient === '-1' && familia === 'SHO') {
            errors = {
                ...errors,
                isClient: {
                    message: 'Selecciona si es un Cliente Claro.'
                }
            }
        }

        if (!name || !(new RegExp("^[ a-zA-ZÃ¡-ÃºÃ-Ãš]+ [ a-zA-ZÃ¡-ÃºÃ-Ãš]{1,40}$").test(name))) {
            errors = {
                ...errors,
                name: {
                    message: 'Ingresa tu Nombre y Apellido'
                }
            }
        }

        if (!email) {
            errors = {
                ...errors,
                email: {
                    message: 'Ingresa tu Email'
                }
            }
        } else if(!(new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,50}$/i).test(email))){
            errors = {
                ...errors,
                email: {
                    message: 'El correo es incorrecto'
                }
            }
        }

        if (!rut) {
            errors = {
                ...errors,
                rut: {
                    message: 'Ingresa tu Rut'
                }
            }
        } else if(rut.length < 9){
            errors = {
                ...errors,
                rut: {
                    message: 'Ingresa un Rut Válido'
                }
            }
        }

        if (!phoneContact) {
            errors = {
                ...errors,
                phoneContact: {
                    message: 'Ingresa tu Rut'
                }
            }
        } else if(phoneContact.length < 8){
            errors = {
                ...errors,
                phoneContact: {
                    message: 'Ingresa un Rut Válido'
                }
            }
        }

        setErrors(errors)

        console.log(errors)

        if (Object.keys(errors).length === 0) {
            procesoSolicitud()
        } else {
            setDisabledContinua(false)
        }
    }

    const numberValid = value => {
        return isNaN(value) ? false : true
    }
    
    const onChangeNumber = (value, f, l) => {
        if (numberValid(value) && value.length <= l) {
            f(value)
        }
    }

    const onFocusRut = value => {
        setRut(value.replace('-',''))
    }

    const onBlurRut = value => {
        console.log("blur")
        if(value.length > 1){
            let rutFormat = `${value.slice(0,value.length - 1)}-${value.charAt(value.length - 1)}`
            setRut(rutFormat)
        } else {
            setRut('')
        }
    }

    async function procesoSolicitud() {
        let segmento = isClient === 'si' ? 'personas_hogar_cliente' : 'personas_hogar_no_cliente'; 
        let origin = isClient === 'si' ? 'Chat_CrossIN' : 'Chat_Hogar';
        let formData = new FormData()
        formData.append('process', 'send')
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phoneContact)
        formData.append('rut', rut)
        formData.append('deptid', 'qOFdiGh6bG0=')
        formData.append('segmento', segmento)
        formData.append('origin', origin)
        formData.append('chat_mx', chat_mx)   
        h++ 
        if(h== 1){
            const configCall_Chat = {
                method: 'POST',
                url: 'https://digital.clarochile.cl/wcm-iframe/c2chat-negocios/assets/inc/chat_calls.php',
                data: formData,
                headers: {
                    'Accept': "application/json",
                    'Content-Type': 'multipart/form-data'
                }
            }

            try{
                console.log("response- CallChat configCall_Chat------------------------->", configCall_Chat);
                let response = await axios(configCall_Chat)
                console.log("response- CallChat response------------------------->", response);
                let result = response.data
                result = result.replace('json_c2chat(',"")
                result = result.replace('\')',"")
                result = result.substring(1, result.length)
                result = JSON.parse(result)
                console.log("response- CallChat parse------------------------->", result);
                
                if(result.result === 0){
                    setShowForm(false)
                    setURLFrame(result.service)
                } else {
                    console.log('chat')
                }
            }catch(error){
                console.log(error)
    
            }
            setDisabledContinua(false)
        }    
        

       
    }
    useEffect(() => {
        setChat_mx("chat_mx")
    },[])

    return(
        <div className='modalClaro mSolicitarSales'>
            <div className="mSolicitarSalesCont">
            {showForm
                ?
                <form className="mSolSales" onSubmit={(e) => onSubmitForm(e)}>
                    <p className="mSolSalTitle">Chat de Ventas</p>
                    <p className="mSolSalDesc">Cuentanos de ti para poder chatear con un Ejecutivo:</p>
                    {familia === 'SHO' &&
                        <fieldset> 
                        
                            <label>¿Eres Cliente de Claro Chile?</label>
                            <span className="select">
                                <select value={isClient} onChange={e => setIsClient(e.target.value)}  defaultValue='-1'>
                                    <option value="-1" disabled>Seleccione una opción</option>
                                    {[
                                        {value: 'no', label: 'No soy Cliente de Claro Chile'}, 
                                        {value: 'si', label: 'Si soy Cliente de Claro Chile'},
                                    ].map(item => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                    
                                </select>
                            </span>
                            {errors?.isClient?.message &&
                                    <div className="notification error">
                                        <p>
                                            {errors?.isClient?.message}
                                        </p>
                                    </div>
                            }
                        </fieldset>
                    }
                    <div className="mSolSalesCol2">
                        <fieldset> 
                            <TextField
                                placeholder='Ingresa tu nombre completo'
                                className=''
                                // className='inputField'
                                value={name} 
                                onChange={e => setName(e.target.value)} 
                            >
                                Nombre y Apellido
                            </TextField>
                            {errors?.name?.message &&
                                <div className="notification error">
                                    <p>
                                        {errors?.name?.message}
                                    </p>
                                </div>
                            }
                        </fieldset>
                        <fieldset> 
                            <TextField
                                placeholder='Ingresa tu RUtT'
                                className=''
                                // className='inputField'
                                value={rut} 
                                maxLength={9}
                                onChange={(e) => onChangeNumber(e.target.value,setRut,9)}
                                onFocus={(e) => onFocusRut(e.target.value)}
                                onBlur={(e) => onBlurRut(e.target.value)}
                            >
                                RUT
                            </TextField>
                            {errors?.rut?.message &&
                                <div className="notification error">
                                    <p>
                                        {errors?.rut?.message}
                                    </p>
                                </div>
                            }
                        </fieldset>
                    </div>
                    <div className="mSolSalesCol2">
                        <fieldset> 
                            <TextField
                                placeholder='Ingresa tu correo'
                                className=''
                                // className='inputField'
                                value={email} 
                                onChange={e => setEmail(e.target.value)}  
                            >
                                Correo Electronico
                            </TextField>
                            {errors?.email?.message &&
                                <div className="notification error">
                                    <p>
                                        {errors?.email?.message}
                                    </p>
                                </div>
                            }
                        </fieldset>
                        <fieldset> 
                            <TextField
                                placeholder='Ingresa tu teléfono'
                                type='text'
                                className=''
                                // className='inputField'
                                maxLength={9}
                                value={phoneContact} 
                                onChange={e => onChangeNumber(e.target.value,setPhoneContact,9)} 
                            >
                                Teléfono de Contacto
                            </TextField>
                            {errors?.phoneContact?.message &&
                                <div className="notification error">
                                    <p>
                                        {errors?.phoneContact?.message}
                                    </p>
                                </div>
                            }
                            <input type="hidden" value={chat_mx} id="chat_mx" name="chat_mx" />
                        </fieldset>
                    </div>
                    <div className="mSolSalesActions">
                        <button className={`btn btnPrimario ${disabledContinua ? 'disabled' : ''}`}  disabled={disabledContinua} type='submit' > Enviar </button>
                        <a className="textLink" id="mSolSelesOptionsBtn" href="#!" onClick={onClose}>Ver otras opciones</a>
                        {/* <a className="textLink" id="mSolSelesOptionsBtn" href="javascript:;">Ver otras opciones</a> */}
                    </div>
                </form>
                :
                <iframe title='ChatCL' width={'550px'}  height={'600px'}src={URLFrame} />
            }
            </div>
        </div>
    )
}

MSolicitarSalesPart.defaultProps = {
    onSubmit: (data)=>{}
}

MSolicitarSalesPart.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
}

export default MSolicitarSalesPart