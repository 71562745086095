import React, { useState, useRef, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import useWindowSize from '../../../../Hooks/useWindowSize'

//Libraries
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Mol
import Modal, { ModalSize } from '../../Modal'
import MChannelsPartModal from '../../Modal/Part/MChannels'
import MServiceHomePartModal from '../../Modal/Part/MServiceHome'
import MSolicitarPartModal from '../../Modal/Part/MSolicitar'
import MSolicitarSalesPartModal from '../../Modal/Part/MSolicitarSales'
import Parser from 'html-react-parser';

//import InputSelect, { InputSelectType } from '../../../Atoms/InputSelect'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


function 
General2PlanCardMol(props) {
    const {
        title,
        titlestyle,
        name,
        //changeCompare,
        fi_PLAN,
        // fi_ID_TELEFONIA,
        // fi_ID_TELEVISION,
        // fi_ID_INTERNET,
        // fi_CATEGORIA,
        // fc_TIPO_DISPONIBILIDAD,
        // fc_TEXTO_CAJA,
        // fc_COLOR_CAJA,
        // fc_NOMBRE,
        fc_DESCRIPCION,
        // fi_NUMERO_CARACTERISTICAS_MOSTRAR,
        fc_TITULO_CARACTERISTICAS_AGRUPADAS,
        fi_PRECIO_PLAN,
        // fi_PRECIO_TELEFONIA_ENPS,
        // fi_PRECIO_TELEVISION_ESPN,
        // fi_PRECIO_INTERNET_ESPS,
        // fc_COLOR_PRECIO,
        fc_ETIQUETA_PRECIO_TACHADO,
        fc_PRECIO_TACHADO,
        fc_NOTA_DEBAJO_PRECIO,
        fc_TEXTO_DETALLE,
        fc_URL_DETALLE,
        fc_TARGET_DETALLE,
        // fi_BAN_DETALLE,
        fc_OPCION_CONTRATACION,
        fc_TEXTO_CONTRATAR,
        fc_URL_CONTRATAR,
        fc_TARGET_CONTRATAR,
        // fi_BAN_CONTRATAR,
        // fi_ORDEN,
        // fi_ACTIVO,
        // fi_ID_DASHBOARD,
        // fi_BAN_ENPN,
        // fi_BAN_ENPS,
        // fi_BAN_ESPN,
        // fi_BAN_ESPS,
        // fc_ETIQUETA_PROMOCION_ATP,
        // fi_PRECIO_AHORRO,
        fc_ETIQUETA_PERIODO,
        fc_TITULO_PROMO,
        Caracteristicas,
        caracteristicasPromocion,
        Acordeon,
        //keysPlanCompare,
        lstPlanContratacionxPlan,
        Discount,
        fi_INCLUIR_SECCION,
        fc_ETIQUETA_ROJA,
        fc_TITULO_SECCION2,
        fc_DESCRIPCION_SECCION,
        fc_TEXTO_BOTON,
        fc_TARGET_BOTON,
        fc_URL_BOTON,
        fc_DESCRIPCION_OFERTA,
        fc_PLAN_ICONO,
        fi_SERVICIO,
        fc_NOTA_DETALLE_PROMOCION,
        dataChannelsBk,
        dataCategoriasBk,
        fc_DETALLE_PLAN,
    } = props
    let newAcordeon = Acordeon?.map((item, index) => {
        if (!item?.open) {
            item.open = false
        }
        return item;
    })

    const cardPricesRef = useRef()

    const {
        // height: heightWindow,
        width: widthWindow,
    } = useWindowSize()

    const ajustarCard = function (className) {
        var altura, i, maxHeight, total;
        var els = document.getElementsByClassName(className)

        // Limpia todos los height
        Array.prototype.forEach.call(els, function (el) {
            el.style.height = ''
        });

        // Se optienen todos los height
        altura = [];
        total = els.length - 1
        i = 0;
        while (i <= total) {
            altura.push(els[i].offsetHeight);
            i++;
        }
        // Se optiene la height maxima entre todas las tarjetas
        maxHeight = Math.max.apply(null, altura);

        // Se aplica a todos la height
        Array.prototype.forEach.call(els, function (el) {
            // console.log(el.offsetHeight);
            el.style.height = maxHeight + 'px'
        });
    }

    // ajusta siempre que cambia
    useEffect(() => {
        ajustarCard('cPlanV2Head')
        ajustarCard('cPlanV2Prices')
        ajustarCard('cPlanV2Promo')
        ajustarCard('cPlanV2ContentDetails')

    })

    useEffect(() => {
        ajustarCard('cPlanV2Head')
        ajustarCard('cPlanV2Prices')
        ajustarCard('cPlanV2Promo')
        ajustarCard('cPlanV2ContentDetails')
    }, [widthWindow])

    const modalServiceRef = useRef()
    const modalRequestRef = useRef()
    const modalSalesRef = useRef()
    const openModalService = () => modalServiceRef.current.open()
    const openModalRequest = () => modalRequestRef.current.open()
    const openModalSales = () => modalSalesRef.current.open()
    const closeModalSales = () => modalSalesRef.current.close()

    const onClickSeeMore = () => {
        console.log('Conoce más')
        openModalService()
    }
    const onClickRequest = () => {
        console.log('Contratar')
        openModalRequest()
    }

    const modalChannelsRef = useRef()
	const openModalChannels = () => modalChannelsRef.current.open()
	const closeModalChannels = () => modalChannelsRef.current.close()

    const onClickChangeChannels = () => {
		openModalChannels()
	}
	const onClickChangeChannelsClose = () => {
		closeModalChannels()
	}

    /*const toChecked = keysPlanCompare.filter(function (keyData) {
        if (keyData === fi_PLAN) {
            return true
        } else {
            return false
        }
    })*/

    const searchCharacteristics = (search, TypeFlag = false, Caracteristicas) => {
        let urlModChaCond = 0;
        if (TypeFlag) {
            const data = Caracteristicas?.length ? Caracteristicas : [];
            const newCaractData = data.filter(function (CaracData) {
                let CaracDESCRIPCION = []

                CaracDESCRIPCION = CaracData.fc_DESCRIPCION

                return CaracDESCRIPCION.indexOf(search) > -1
            })
            // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
            return newCaractData
        } else {
            const data = Caracteristicas?.length ? Caracteristicas : [];
            const newCaractData = data.filter(function (CaracData) {
                let CaracDESCRIPCION = []

                CaracDESCRIPCION = CaracData.fc_DESCRIPCION

                return CaracDESCRIPCION.indexOf(search) === -1
            })
            newCaractData.forEach((c, index)=>{
        
                if(c.fc_TITULO.toLowerCase().indexOf("canal")>-1 && urlModChaCond === 0 && c?.SETURLMODCH === undefined){
                    c.fc_VALOR = c.fc_VALOR;
                    c.SETURLMODCH = true
                    urlModChaCond = 1
                }
            })
            // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
            return newCaractData
        }
    }
    const planData = [
        { id_plan: 406, fi_plan: 292},
        { id_plan: 407, fi_plan: 18 },
        { id_plan: 404, fi_plan: 292 },
        { id_plan: 405, fi_plan: 18 },
        { id_plan: 105, fi_plan: 292 },
        { id_plan: 389, fi_plan: 18 },
        { id_plan: 390, fi_plan: 292},
        { id_plan: 391, fi_plan: 18 },
        { id_plan: 392, fi_plan: 292 },
        { id_plan: 393, fi_plan: 18 },
        { id_plan: 394, fi_plan: 292},
        { id_plan: 401, fi_plan: 18 },
        { id_plan: 399, fi_plan: 292 },
        { id_plan: 402, fi_plan: 18 },
        { id_plan: 400, fi_plan: 292},
        { id_plan: 403, fi_plan: 18 },
        { id_plan: 413, fi_plan: 292 },
        { id_plan: 414, fi_plan: 18},

    ]; 

    const getChannesByPlanId = async (IdPlan) => {
        console.log(IdPlan);
        let channels = [];
        console.log("datachanelsbk:"+dataChannelsBk);
    
        let foundMatchingPlan = false;
        planData.forEach(plan => {
            if (IdPlan == plan.id_plan) {
                foundMatchingPlan = true;
                channels = dataChannelsBk.filter(c => {
                    let PlanFi = c.lstplanes.some(pl => pl.fi_plan === plan.fi_plan);
                    return PlanFi;
                });
            }
        });
    
        if (!foundMatchingPlan) {
            dataChannelsBk?.forEach((c, index) => {
                let arrayFilt = c.lstplanes.filter(pl => pl.fi_plan === IdPlan);
                if (arrayFilt.length) channels.push(c);
            });
        }
    
        if (channels.length){
            let catIncludes = [];
            channels.forEach((c, index) => {
                catIncludes.push(c.fi_categoria);
            });
    
            const listCategories = dataCategoriasBk.filter(ca => catIncludes.includes(ca.fi_categoria));
            setDataCategorias(listCategories);
        }
        setDataChannels(channels);
        console.log("getChannesByPlanId listChannels General2", IdPlan, channels, dataChannels);
        // return channels
    }
    useEffect(() => {
        getChannesByPlanId(fi_PLAN)
        // eslint-disable-next-line
    }, [])
    
    const [detailsCond, setDetailsCond] = useState(false)
    const [dataChannels, setDataChannels] = useState([])
    const [dataCategorias, setDataCategorias] = useState([])
    let CharactBlock = searchCharacteristics('Tipo Footer', false, Caracteristicas)
    let CharactFooter = searchCharacteristics('Tipo Footer', true, Caracteristicas)
    const symbolPrice = "$";
    let flagModChannel = localStorage.getItem('id_vigencia') === "CHN" ? true : false;

    const settingsSliderIcons = {
        infinite: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        arrows: true
    }

    return (
        <>
            <div className={'cPlanV2Wrap ' + (titlestyle ? titlestyle : 'amarillo')}>
                <div className="cPlanV2">
                    {
                        title
                            ?
                            <div className="cPlanV2Flag">
                                <p>{title}</p>
                            </div>
                            :
                            null
                    }
                    <div className="cPlanV2Head">
                        <div className="cPlanV2Icon">
                            {
                                fc_PLAN_ICONO
                                    ?
                                    <i className={fc_PLAN_ICONO}></i>
                                    : null
                            }
                        </div>
                        <div className="cPlanV2HeadCont">
                            <h3 className="h3">{name}</h3>
                            <div className="cPlanV2HeadInf">
                                {/* <p>{name}</p> */}
                                {
                                    fc_DESCRIPCION ?
                                        <p>
                                            {fc_DESCRIPCION}
                                        </p>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="cPlanV2Prices" ref={cardPricesRef}>
                        <div className="cPlanV2PricesTitle">
                            {
                                fc_DESCRIPCION_OFERTA
                                    ?
                                    <p>{fc_DESCRIPCION_OFERTA}</p>

                                    : null
                            }
                        </div>
                        <div className="cPlanV2PricesPrice">
                            {/* <p>$25.990</p> */}
                            {
                                fi_PRECIO_PLAN ?
                                    <p>
                                        {symbolPrice}{fi_PRECIO_PLAN}
                                    </p>
                                    : null
                            }
                        </div>
                        <div className="cPlanV2PricesTime">
                            {/* <p>mensual por 11 meses</p> */}
                            {
                                fc_ETIQUETA_PERIODO ?
                                    <p>
                                        {fc_ETIQUETA_PERIODO}
                                    </p>
                                    : null
                            }
                        </div>
                        <div className="cPlanV2PricesNote">
                            {/* <p>cargo fijo normal: <del>$37.990</del> mensual</p> */}
                            {
                                fc_ETIQUETA_PRECIO_TACHADO && fc_PRECIO_TACHADO ?

                                    <p>{fc_ETIQUETA_PRECIO_TACHADO}: {symbolPrice} <del>{fc_PRECIO_TACHADO}</del></p>

                                    : null
                            }
                        </div>

                        {
                            fc_NOTA_DEBAJO_PRECIO ?
                                <div className="cPlanV2PricesPlaque">
                                    <p>
                                        {Parser(fc_NOTA_DEBAJO_PRECIO)}
                                    </p>
                                </div>
                                : null
                        }
                        {
                            Discount != null ?
                                <div className="cPlanV2Tag">
                                    <picture>
                                        <source media="(max-width: 640px)" data-srcset="/portal/cl/recursos_tema/assets/img/cardPlanV2Disc.webp" type="image/webp" />
                                        <source media="(max-width: 640px)" data-srcset="/portal/cl/recursos_tema/assets/img/cardPlanV2Disc.png" type="image/jpeg" />
                                        <source media="(min-width: 640px)" data-srcset="/portal/cl/recursos_tema/assets/img/cardPlanV2Disc.webp" type="image/webp" />
                                        <source media="(min-width: 640px)" data-srcset="/portal/cl/recursos_tema/assets/img/cardPlanV2Disc.png" type="image/jpeg" />
                                        <img className="lazyload" data-src="/portal/cl/recursos_tema/assets/img/cardPlanV2Disc.png" alt="" width="100" height="55" />
                                    </picture>

                                    <div className='cPlanV2TagCont'>
                                        <div className="cPlanV2TagNumber">
                                            <p>{Discount}</p>
                                        </div>
                                        <div className='cPlanV2TagPor'>
                                            <p className='disc'>%</p>
                                            <p className='note'>DCTO</p>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className="cPlanV2Tag"></div>
                        }

                    </div>
                    {
                        fc_TITULO_PROMO || caracteristicasPromocion?.length
                            ?
                            <div className="cPlanV2Promo">
                                <p>{fc_TITULO_PROMO ? fc_TITULO_PROMO : null}</p>
                                 <Slider {...settingsSliderIcons} className="cPlanV2PromoItemsSlider">
                                {/*<div className="cPlanV2PromoItems">*/}
                                   {
                                        caracteristicasPromocion?.length
                                            ?
                                            caracteristicasPromocion.map((caract, index) => (
                                                caract?.fc_TIPO_VALOR_CARACTERISTICA.includes("IMG") ?
                                                    <picture key={uuid()}>
                                                        <source media="(max-width: 640px)" data-srcset={caract?.fc_VALOR.split(".")[0] + ".webp"} type="image/webp" />
                                                        <source media="(max-width: 640px)" data-srcset={caract?.fc_VALOR} type={"image/" + caract?.fc_VALOR.split(".")[1]} />
                                                        <source media="(min-width: 640px)" data-srcset={caract?.fc_VALOR.split(".")[0] + ".webp"} type="image/webp" />
                                                        <source media="(min-width: 640px)" data-srcset={caract?.fc_VALOR} type={"image/" + caract?.fc_VALOR.split(".")[1]} />
                                                        <img className="lazyload" data-src={caract?.fc_VALOR} alt={caract?.fc_ALT_IMG} width="75" height="25" />
                                                    </picture>
                                                    :
                                                    <i key={uuid()} className={caract?.fc_VALOR}></i>
                                            ))
                                            : null
                                    }
                                {/*</div>*/}
                                 </Slider>
                            </div>
                            : null
                    }
                    <div className={`cPlanV2Content cPlanV2ContentMore ${detailsCond ? 'openDetails' : ''}`}>
                        <div className="cPlanV2ContentOpen" onClick={() => { setDetailsCond(c => !c) }}>
                            <p>{fc_DETALLE_PLAN ? fc_DETALLE_PLAN : "Ver detalle de plan"}</p>
                            <i className="ico-chevron-down"></i>
                        </div>
                    { detailsCond && 
                    <>
                        <div className="cPlanV2ContentDetails">
                            {
                                CharactBlock ?
                                    CharactBlock.map((caract, index) => (
                                        caract.fc_VALOR.includes("logo") ?
                                            <dl key={uuid()} className="cPlanV2DL">

                                                <dt>
                                                    <span className={`${caract.fc_VALOR}`}></span>
                                                </dt>
                                                <dd>{caract.fc_TITULO}</dd>
                                            </dl>
                                            :
                                            <dl key={uuid()} className={`cPlanV2DL ${caract.fc_CLASE_ICONO && !caract.fc_DESCRIPCION.includes("Redes Sociales") ? 'iconEle' : ''}`}>

                                                {
                                                    caract.fc_CLASE_ICONO
                                                        ?
                                                        <dt>
                                                            {
                                                                caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                                ? null
                                                                : <i className={caract.fc_CLASE_ICONO}></i>
                                                            }
                                                            <span>{caract.fc_TITULO}</span>
                                                        </dt>
                                                        :
                                                        <dt>
                                                            {caract.fc_TITULO}
                                                        </dt>
                                                }

                                                {
                                                    caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                        ?
                                                        <dd className="cPlanRedS">
                                                            <ul>
                                                                {
                                                                    caract.fc_VALOR.split("|").map((icoRed, index) => (
                                                                        <li key={uuid()}>
                                                                            <span>
                                                                                <i className={icoRed} />
                                                                            </span>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </dd>
                                                        :
                                                        <dd>
                                                            {
                                                                dataChannels.length > 0 && caract.fc_TITULO.toLowerCase().indexOf("canal") >-1 && 
                                                                caract?.SETURLMODCH === true  && flagModChannel && caract.fc_TITULO.toLowerCase().indexOf("audio") < 0 ?
                                                                <>
                                                                    <span className="ddExtra">{caract.fc_VALOR}</span>
                                                                    <a href="#!" className="textLink" onClick={()=>{onClickChangeChannels()}}>Ver canales</a>
                                                                </>
                                                                :
                                                                caract.fc_VALOR
                                                            }
                                                        </dd>
                                                }
                                            </dl>
                                    ))

                                    : null
                            }
                            {
                                CharactFooter ?
                                    CharactFooter.map((caract, index) => (
                                        <div key={uuid()} className="cPlanIconBlock">
                                            <span className="iconCircle">
                                                {
                                                    caract.fc_CLASE_ICONO ?
                                                        <i className={caract.fc_CLASE_ICONO}></i>
                                                        : null
                                                }

                                            </span>
                                            <p>
                                                <strong> {caract.fc_TITULO}</strong>
                                                <span> {caract.fc_VALOR}</span>
                                            </p>
                                        </div>
                                    ))
                                    : null
                            }
                            <dl className="cPlanV2DL">
                                <div className="cPlanV2DLAction">
                                    {
                                        fc_TEXTO_DETALLE ?
                                            fc_URL_DETALLE !== "$URL_DEFAULT_DETALLE$"
                                                ?
                                                <a href={fc_URL_DETALLE} target={fc_TARGET_DETALLE ? fc_TARGET_DETALLE : "_self"}>
                                                    {fc_TEXTO_DETALLE}
                                                </a>
                                                :
                                                <a href="#!" target={fc_TARGET_DETALLE ? fc_TARGET_DETALLE : "_self"} onClick={() => onClickSeeMore()}>
                                                    {fc_TEXTO_DETALLE}
                                                </a>
                                            : null
                                    }
                                </div>
                            </dl>
                        </div>
                        
                        <div className="cPlanV2ContentClose" onClick={() => { setDetailsCond(c => !c) }}>
                            <p>Ocultar</p>
                            <i className="ico-chevron-up"></i>
                        </div>
                        </>
                        }
                    </div>
                    <div className="cPlanV2Actions">
                        {
                            fc_TEXTO_CONTRATAR ?
                                fc_OPCION_CONTRATACION === "URL" ?
                                    <a className="btn btnPrimario" href={fc_URL_CONTRATAR} target={fc_TARGET_CONTRATAR ? fc_TARGET_CONTRATAR : '_self'}>
                                        {fc_TEXTO_CONTRATAR}
                                    </a>
                                    :
                                    <a className="btn btnPrimario" href="#!" onClick={() => onClickRequest()} target={'_self'}>
                                        {fc_TEXTO_CONTRATAR}
                                    </a>
                                : null
                        }
                    </div>
                    {/*
                        changeCompare
                            ?
                            <div className='cPlanV2Compare'>

                                <InputSelect
                                    type={InputSelectType.CHECKBOX}
                                    onChange={changeCompare}
                                    name={fi_PLAN}
                                    checked={toChecked.length ? true : false}
                                    disabled={keysPlanCompare.length >= 4 && !toChecked.length ? true : false}
                                >
                                    Comparar
                                </InputSelect>
                            </div>
                            : null

                    */}
                </div>
                {
                    fi_INCLUIR_SECCION === 1
                        ?
                        <div className="cPlanV2Offer cPlanV2OfferShow">
                            {
                                fc_ETIQUETA_ROJA &&
                                <p className="cPlanV2OfferLeft">{fc_ETIQUETA_ROJA}</p>
                            }
                            <div className="cPlanV2OfferCont">
                                {
                                    fc_TITULO_SECCION2 &&
                                    <p>{fc_TITULO_SECCION2}</p>
                                }
                                {
                                    fc_DESCRIPCION_SECCION &&
                                    <p>{fc_DESCRIPCION_SECCION}</p>
                                }
                                {
                                    fc_TEXTO_BOTON &&
                                    <div className="cPlanV2OfferAction">
                                        <a href={fc_URL_BOTON} target={fc_TARGET_BOTON ? fc_TARGET_BOTON : '_self'}>
                                            {fc_TEXTO_BOTON}
                                            <i className="ico-chevron-right"></i>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        : null
                }
            </div>
            <Modal 
				ref={modalChannelsRef}
				size={ModalSize.BIG}
			>
				<MChannelsPartModal
					onClose={onClickChangeChannelsClose}
					dataChannels={dataChannels}
					planName={name + " " + fc_DESCRIPCION}
                    dataCategorias={dataCategorias}
				/>
			</Modal>
            <Modal
                ref={modalServiceRef}
                size={ModalSize.MEDIUM_2}
            >
                <MServiceHomePartModal
                    asks={newAcordeon}
                    Caracteristicas={Caracteristicas}
                    title={title}
                    name={name}
                    fc_DESCRIPCION={fc_DESCRIPCION}
                    fi_PRECIO_PLAN={fi_PRECIO_PLAN}
                    fc_ETIQUETA_PERIODO={fc_ETIQUETA_PERIODO}
                    titlestyle={titlestyle}
                    symbolPrice={symbolPrice}
                    fc_TITULO_CARACTERISTICAS_AGRUPADAS={fc_TITULO_CARACTERISTICAS_AGRUPADAS}
                    fc_NOTA_DETALLE_PROMOCION={fc_NOTA_DETALLE_PROMOCION}
                />
            </Modal>
            <Modal
                ref={modalRequestRef}
                size={ModalSize.SMALL}
            >
                <MSolicitarPartModal
                    modalRef={modalRequestRef}
                    openSales={openModalSales}
                    title={title}
                    name={name}
                    fc_DESCRIPCION={fc_DESCRIPCION}
                    fi_PRECIO_PLAN={fi_PRECIO_PLAN}
                    fc_ETIQUETA_PERIODO={fc_ETIQUETA_PERIODO}
                    lstPlanContratacionxPlan={lstPlanContratacionxPlan}
                    fi_SERVICIO={fi_SERVICIO}
                />
            </Modal>
            <Modal 
                ref={modalSalesRef}
                size={ModalSize.MEDIUM_3}
            >
                <MSolicitarSalesPartModal 
                    onClose={()=>{closeModalSales();openModalRequest()}}
                />   
            </Modal>
        </>
    )
}

export default General2PlanCardMol
