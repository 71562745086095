import { HttpClient } from "./HttpClient";
import { GET_CATEGORIES, GET_CHANNELS, GET_PLANES } from "../constants/endPoints"
export class TodoService {

    constructor(http = HttpClient) {
        this.http = http
    }

    async getTodoItems() {
        const { data } = await this.http.get('https://jsonplaceholder.typicode.com/todos/');
        return data;
    }
}

export class CategoriesService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getCategoriesItems() {
        const { data } = await this.http.post(GET_CATEGORIES)
        return data
    }
}

export class ChannelsService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getChannelsItems() {
        const { data } = await this.http.post(GET_CHANNELS)
        return data
    }
}

// export class CoverageTypeService {
//     constructor(http = HttpClient) {
//         this.http = http
//     }

//     async getCoverageTypeItems() {
//         const { data } = await this.http.post(GET_COVERAGE_TYPES)
//         return data
//     }
// }

// export class PaqueteService {
//     constructor(http = HttpClient) {
//         this.http = http
//     }

//     async getPaqueteItems() {
//         const { data } = await this.http.post(GET_PAQUETES)
//         return data
//     }
// }

export class PlanService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getPlanItems() {
        const { data } = await this.http.post(GET_PLANES)
        return data
    }
}