import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const TextFieldStatus = {
    DEFAULT: '',
    SUCCESS: 'input-success',
    ERROR: 'input-error',
}

export const TextFieldTheme = {
    LIGHT: '',
    DARK: 'input-dark',
}

export const TextFieldLabelType = {
    DEFAULT: '',
    BOLD: 'textFieldLabelBold',
}

const TextField = React.forwardRef ( (props, ref) => {
    const {
        // ref,
        id,
        className,
        placeholder,
        value,
        name,
        onChange,
        disabled,
        pattern,
        maxLength,
        max,
        min,
        autoComplete,
        ariaLabel,
        theme,
        status,
        children,
        type,
        step,
        labelType, 
    } = props

    const parentClass = classNames(
        'textField',
        className
    )
    const classProps = classNames(
        '',
        status,
        theme,
    )

    const classPropsLabel = classNames(
        {'': theme === TextFieldTheme.LIGHT},
        {'blanco': theme === TextFieldTheme.DARK},
        'textFieldLabel',
        labelType,
        // {[TextFieldStatus.SUCCESS]: status === TextFieldStatus.SUCCESS},
        // {[TextFieldStatus.ERROR]: status === TextFieldStatus.ERROR},
    )

    const classIconStatus = classNames(
        'status',
        {'icon-success': status === TextFieldStatus.SUCCESS},
        {'icon-error-2': status === TextFieldStatus.ERROR},
    )

    return (
        <div className={parentClass}>
            {children && 
                <label htmlFor={id}  className={classPropsLabel}>
                    {children}
                </label>
            }

            <input
                ref={ref}
                id={id}
                className={classProps} 
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                pattern= {pattern}
				maxLength= {maxLength}
				max= {max}
                min= {min}
                aria-label={ariaLabel}
                autoComplete= {autoComplete}
                step={step}
            />
            {
                status &&
                    <span className={classIconStatus} />
            }
        </div>
    )
})

TextField.propTypes = {
    ref : PropTypes.func,
    className : PropTypes.string,
    placeholder : PropTypes.string,
    value : PropTypes.string,
    name : PropTypes.string,
    onChange : PropTypes.func,
    disabled: PropTypes.bool,
    pattern : PropTypes.string,
    maxLength : PropTypes.string,
    max : PropTypes.string,
    min : PropTypes.string,
    autoComplete : PropTypes.string,
    ariaLabel : PropTypes.string,
    
    theme : PropTypes.string,
    children : PropTypes.node,
    type: PropTypes.string,
    step: PropTypes.number,
    labelType: PropTypes.string,
}
TextField.defaultProps = {
    // ref: null,
    id: null,
    className: '',
    placeholder: '',
    // value: '',
    name: '',
    onChange: () => {},
    disabled: false,
    // pattern: '[0-9]',
    // maxLength :'1' ,
    // max:'z',
    // min:'0',
    autoComplete:'off',
    // ariaLabel:"Algun valor",
            
    theme: TextFieldTheme.LIGHT,
    status: TextFieldStatus.DEFAULT,
    children: '',
    type: 'text',
    // step: 1,
    labelType: TextFieldLabelType.DEFAULT
}

export default memo( TextField)